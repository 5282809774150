import gql from 'graphql-tag';

import { PAGINATION_FRAGMENT } from '../lib/graphql';

export const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    orderSeqId
    prettyId
    createdBy {
      id
      fullName
    }
    billingCustomer {
      id
      fullName
      email
    }
    tickets {
      id
      ticketNumber
      ticketNumber2ndGen
      ticketType {
        id
        name
        normalPrice
        normalPriceInHuf
        normalPriceInEur
        elbCardPrice
        elbCardPriceInHuf
        elbCardPriceInEur
      }
      event {
        id
        name
      }
      status
      priceInHuf
      priceInEur
      soldAt
      soldBy {
        id
        fullName
        email
      }
      customer {
        id
        fullName
        email
      }
      elbCard {
        id
      }
    }
    elbCards {
      id
      customer {
        id
        fullName
        email
      }
      type {
        id
        name
        price
        priceInHuf
        priceInEur
      }
      createdAt
      expirationDate
      status
      cardNumber
      onlineCardNumber
      soldBy {
        id
        fullName
        email
      }
      elbCardProductSnapshot {
        productId
        name
        type
      }
    }
    v2Tickets {
      id
      customer {
        id
        fullName
        email
      }
      event {
        id
        name
      }
      product {
        name
        price
        priceInHuf
      }
      ticketNumber
    }
    products{
      productId
      name
      description
      priceInEur
      priceInHuf
      type
      imageUrl
      eventId
      isWebshopPurchasable
      includedInOrderEmail
      isMembershipGranted
      membershipExpiresInDays
      customer {
        membership {
          expiresAt
          orderId
        }
        id
        email
        firstName
        lastName
        customerSeqId
      }
    }
    expiresAt
    createdAt
    status
    amount
    items
    payment {
      cashSubtotal
      cardSubtotal
    }
    terminal {
      id
      name
      account {
        id
        name
      }
    }
    paymentChannel
    createdDate
    completedAt
    exchangeRate
    currency
  }
`;

export const COMPLETE_ORDER = gql`
  mutation CompleteOrder($data: CompleteOrderInput) {
    completeOrder(data: $data) {
      payment {
        cashSubtotal
        cardSubtotal
      }
      terminal {
        id
        name
      }
      currency
      exchangeRate
    }
  }
`;

export const COMPLETE__PRODUCT_ORDER = gql`
  mutation CompleteProductOrder($data: CompleteOrderInput) {
    completeProductOrder(data: $data) {
      payment {
        cashSubtotal
        cardSubtotal
      }
      terminal {
        id
        name
      }
      currency
      exchangeRate
    }
  }
`;

export const GET_ORDERS = gql`
  query GetOrders($options: OptionsInput) {
    orders(options: $options) @connection(key: "orders") {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...OrderFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${ORDER_FRAGMENT}
`;

export const GET_ORDER = gql`
  query GetOrder($id: String!) {
    order(id: $id) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

/** Order */

export const GET_OPEN_ORDER = gql`
  query GetOpenOrder {
    getOpenOrder {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const ADD_BILLING_CUSTOMER = gql`
  mutation AddBillingCustomer($id: String!) {
    addBillingCustomer(id: $id) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const ADD_PRODUCT_TO_ORDER = gql`
  mutation AddProductToOrder($data: SellProductInput) {
    addProductToOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const REMOVE_PRODUCT_FROM_ORDER = gql`
  mutation RemoveProductFromOrder($id: String!) {
    removeProductFromOrder(id: $id) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const ADD_TICKET_TO_ORDER = gql`
  mutation AddTicketToOrder($data: SellTicketInput) {
    addTicketToOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const ADD_TICKET_PRODUCT_TO_ORDER = gql`
  mutation AddTicketProductToOrder($data: SellTicketInput) {
    addTicketProductToOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const UPDATE_ORDER_TICKET = gql`
  mutation UpdateOrderTicket($data: UpdateOrderTicketInput) {
    updateOrderTicket(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const REMOVE_TICKET_FROM_ORDER = gql`
  mutation RemoveTicketFromOrder($id: String!) {
    removeTicketFromOrder(id: $id) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const ADD_ELB_CARD_TO_ORDER = gql`
  mutation AddELBCardToOrder($data: SellELBCardInput) {
    addELBCardToOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const ADD_ELB_CARD_PRODUCT_TO_ORDER = gql`
  mutation AddELBCardToOrder($data: SellELBCardInput) {
    addELBCardProductToOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const UPDATE_ORDER_ELB_CARD = gql`
  mutation UpdateOrderELBCard($data: UpdateOrderELBCardInput) {
    updateOrderELBCard(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const REMOVE_ELB_CARD_FROM_ORDER = gql`
  mutation RemoveELBCardFromOrder($id: String!) {
    removeELBCardFromOrder(id: $id) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const CLEAR_ORDER = gql`
  mutation ClearOrder {
    clearOrder {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const CLEAR_PRODUCT_ORDER = gql`
  mutation ClearProductOrder {
    clearProductOrder {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;
